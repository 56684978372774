
import { defineComponent } from "vue";
import imagePath from "@/assets/servers.jpg";
import logoPath from "@/assets/logo-grey.png";

export default defineComponent({
  name: "Difference",
  data() {
    return {
      bgImg: imagePath,
      greyLogo: logoPath,
      list: [
        "Proprietary AI to automatically ascertain & disseminate communications by medium type",
        "Approved by DHS, FEMA, US Congress and the US telecoms",
        "Different content drives different channel delivery based on authorization by telecom carriers and aggregators",
        "Enhanced Anti-Hacking Infrastructure using true telecom client-server architecture that meets federal-level security standards for mass communications",
      ],
    };
  },
});
