
import { defineComponent } from "vue";
import NavBar from "@/components/layout/navbar.vue";
import flag from "@/assets/flag-background.jpg";
import blurryBg from "@/assets/red-blur-background.jpg";
import chartsBg from "@/assets/charts-background.jpg";
import teleBg from "@/assets/telecom-background.jpg";

export default defineComponent({
  name: "HomeHeader",
  components: { NavBar },
  data() {
    return {
      activeTab: 0,
      slides: {
        0: {
          title: "Government Approved Mass Notification System",
          subtitle: "Authorized by US Congress in May 2020",
          hasBullets: false,
          heroBackground: blurryBg,
          rgbas: "rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)",
        },
        1: {
          title: "US Census Trusted Data Quality & System Capabilities",
          hasBullets: true,
          bullets: [
            "Telco data use for emergencies and GOTV",
            "Exceeds government security standards - Multiple external collocation sites",
            "Receive actual carrier receipts that demonstrate deliverability",
          ],
          heroBackground: flag,
          rgbas: "rgba(0, 0, 0, 1), rgba(82, 19, 27, 0.9)",
        },
        2: {
          title: "Reporting for Each Communication Launch Including Responses",
          hasBullets: true,
          bullets: [
            "Manage your Data with Increased Knowledge of Data Results",
            "Real-Time Results for Interactive Communications",
            "Reporting insights in Raw and Graphic Data Formats",
          ],
          heroBackground: chartsBg,
          rgbas: "rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)",
        },
        3: {
          heroBackground: teleBg,
          rgbas: "rgba(0, 0, 0, 1), rgba(253, 125, 220, 0.5)",
        },
      },
      disableSlide: false,
    };
  },
  mounted() {
    this.initSlides(1);
  },
  methods: {
    switchTab(x) {
      this.disableSlide = true;
      this.activeTab = x;
    },
    initSlides(x) {
      if (this.disableSlide) return;
      setTimeout(() => {
        this.activeTab = x;
        let y = x + 1;
        if (x === 3) y = 0;
        this.initSlides(y);
      }, 8000);
    },
  },
});
