
import { defineComponent } from "vue";
import imagePath from "@/assets/analysis.jpg";

export default defineComponent({
  name: "ClientValues",
  data() {
    return {
      bgImg: imagePath,
      list: [
        "Unparalleled Data Quality - Telco",
        "Registered Carrier Receipts - Proven Delivery",
        "High Speed Output of ALL Communication Types",
        "Interactive Communications for Information Gathering",
        "Full Quantitative Reporting & Analytics",
      ],
    };
  },
});
