
import { defineComponent } from "vue";
import bg from "@/assets/red-blur-background.jpg";

export default defineComponent({
  name: "ContactRibbon",
  data() {
    return {
      imagePath: bg,
    };
  },
});
