
import { defineComponent } from "vue";
import HomeHeader from "@/components/home/header.vue";
import Communication from "@/components/home/communications-icons.vue";
import Key from "@/components/home/key-features.vue";
import Difference from "@/components/home/difference.vue";
import Mobile from "@/components/home/mobile-screenshots.vue";
import Impact from "@/components/home/impact.vue";
import Client from "@/components/home/client-values.vue";
// import AboutUs from "@/components/home/about.vue";
// import Expertises from "@/components/home/expertises.vue";
// import Strategy from "@/components/home/strategy.vue";
// import Cases from "@/components/home/cases.vue";
// import Testimonials from "@/components/home/testimonials.vue";

export default defineComponent({
  name: "Home",
  components: {
    HomeHeader,
    Communication,
    Key,
    Mobile,
    Difference,
    Impact,
    Client,
  },
});
