
import { defineComponent } from "vue";

export default defineComponent({
  name: "Impact",
  data() {
    return {
      list: [
        "Opt In Requirements",
        "Provide Telephonic Opt-Ins for Your Constituent and/or Voters",
        "Content Requirement (Content Approval by Carriers)",
      ],
      pList: [
        "Individual Notification Requirement",
        "SPAM Implications",
      ],
    };
  },
});
